body {
    padding-top: 110px;
    @media (max-width: 576px){
        padding-top: 62px;
    }
}
.topbar{
    z-index: 100;
    background-color: #0F1932;
    color: #FFF;
    line-height: 40px;
    position: absolute;
    left: 0;right: 0;
    top: 0;
    @media (max-width: 768px){
        display: none;
    }
}
.navbar{
    z-index: 110;
 // min-height: 70px;
 border-bottom: 1px solid #efefef;
 top: 40px;
 left: 0;right: 0;
 @media (max-width: 768px){
     top: 0;
 }
    .navbar-brand {
        img {
            height: auto;
            @media (max-width: 768px){
                width: 200px;
            }
        }
    }
.search{
    .input-group-append{
        padding: 0;
    }
    @media (max-width: 768px){
        display: none;
    }
}
.btn-search-show{
   @media (max-width: 768px){
       display: none;
   } 
}
    .navbar-light .navbar-toggler {
        color: rgba(255, 255, 255, .8);
        border-color: rgba(255, 255, 255, .5);
    }
    &-nav>.nav-item {
        .dropdown-toggle::after{display: none;}
        @media (min-width: 992px){
            &:hover >.dropdownmenu {display: block;}
        }
        >.nav-link {
         
            color: #333;
            @media (max-width: 768px){
                 text-align: center;

            }
            &.active,&:hover{
              color: #f80c0c;
            }
            @media (min-width: 768px) {
                // width: 76px;
                padding: 1rem 1rem!important;

            }
        }
    }
}
/*  */

.dropdownmenu{
    // display: block;
    display: none;
    position: absolute;
    left: 0;right: 0;
    float: none;
    background-color: #efefef;
}
/*  */
.sidenav{
  display: none;
  position: absolute;
  left: 0;right: 0;top: 0;bottom: 0;
  width: 180px;
  z-index: 999;
    left: -200px;
  // background: rgba(0,0,0,0.35);
    box-shadow: 0 0 15px rgba(0,0,0,.5);
    // transition: all .5s;
  .navbar-nav{
    position: absolute;
    height: 100%;
    width: 100%;
    background-color: #333333;
    display: flex;
    padding-top: 30px;
    .nav-item{
      border-bottom: 1px solid #2B2B2B;
      padding-left: 30px;
      .nav-link{
        color: #ADADAD;
      }
    }
  }
}